import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-resume-section',
  templateUrl: './resume-section.component.html',
  styleUrls: ['./resume-section.component.css']
})
export class ResumeSectionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  experienceItems: any = [{
    'startDate': 'Mar 2016', 
    'endDate': 'Present', 
    'jobTitle': 'Software Engineer', 
    'company': 'Paybox Corp', 
    'awesomeIcon': '', 
    'descriptionItems': ['Exercised troubleshooting abilities while resolving both front end and back end application issues. Continued to exhibit best practices while developing code that is both configurable and dynamic across multiple clients. ', 
    'Cooperated with other members of the Development team as well as Project Managers and Quality Assurance team members to analyze, design and build additional components for existing Accounts Receivable and Accounts Payable applications. Also spoke directly with clients when needed to gather appropriate design information and explain functionality.  ', 
    'Demonstrated ability to learn quickly throughout career. One example being learning Xamarin as needed to refactor and develop mobile application pages for the new companion application. ', 
    'Played a key role in onboarding new clients. Assisted in improving setup time by 50% for new clients; Initial client implementation from development to production is now possible within 3 months instead of 6+ months, with Engineering environment setup being streamlined from one week to 2 days.',
    'Demonstrated ability to manage own productivity by working an average of 10% of the time remotely. ']
  }, 
  {
    'startDate': 'Jul 2010', 
    'endDate': 'Mar 2016', 
    'jobTitle': 'Assistant General Manager', 
    'company': 'Panera Bread', 
    'awesomeIcon': '', 
    'descriptionItems':['Worked to advance communication and leadership skills through leading a team of up to 40 associates. Cooperated with the General Manager to meet and exceed company goals and expectations.']
  }];

  skillItems: any = [{
    'name': 'C#',
    'percent': '90'
  }, 
  {
    'name': 'ASP.NET',
    'percent': 90
  }, 
  {
    'name': 'MVC/Web Forms',
    'percent': 80
  }, 
  {
    'name': 'SQL',
    'percent': 90
  }, 
  {
    'name': 'JavaScript',
    'percent': 80
  }, 
  {
    'name': 'HTML/CSS',
    'percent': 95
  },
  {
    'name': 'Xamarin',
    'percent': 65
  }]

  toolItems: any = [{
    'name': 'Visual Studio', 
    'percent': '95'
  },
  {
    'name': 'MS Office', 
    'percent': '95'
  },
  {
    'name': 'SQL Management Studio', 
    'percent': '90'
  },
  {
    'name': 'TFS', 
    'percent': '90'
  },
  {
    'name': 'Git (Azure DevOps)', 
    'percent': '75'
  },
  {
    'name': 'VS Code', 
    'percent': '60'
  }]
}
