import { Component, OnInit, Input } from '@angular/core';
import { ExperienceItem } from '../experience-item';

@Component({
  selector: 'app-resume-item',
  templateUrl: './resume-item.component.html',
  styleUrls: ['./resume-item.component.css']
})
export class ResumeItemComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  @Input()
  itemInfo: ExperienceItem;
}