import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about-section',
  templateUrl: './about-section.component.html',
  styleUrls: ['./about-section.component.css']
})
export class AboutSectionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  travelItems: any[] = [{
    'imageRoute': '../../../../assets/images/PuertoRico.jpg', 
    'location':'El Yunque Rainforest', 
    'details':'Puerto Rico - Jul 2019'
  }, 
  {
    'imageRoute': '../../../../assets/images/Colorado.JPG', 
    'location':'Rocky Mountains', 
    'details':'Colorado - Apr 2019'
  }, 
  {
    'imageRoute': '../../../../assets/images/NorthCarolina.jpg', 
    'location':'Smokey Mountains', 
    'details':'North Carolina - Jan 2019'
  }, 
  {
    'imageRoute': '../../../../assets/images/Arizona.jpg', 
    'location':'Grand Canyon', 
    'details':'Arizona - Apr 2018'
  }]

}
