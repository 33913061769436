import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { NavbarComponent } from './navbar/navbar.component';
import { HeaderItemComponent } from './sharedComponents/header-item/header-item.component';
import { ResumeSectionComponent } from './areas/resume/resume-section/resume-section.component';
import { ResumeItemComponent } from './areas/resume/resume-item/resume-item.component';
import { AboutSectionComponent } from './areas/aboutMe/about-section/about-section.component';
import { TravelItemComponent } from './areas/aboutMe/travel-item/travel-item.component';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { ProgressBarModule } from "angular-progress-bar";
import { ContactSectionComponent } from './areas/contact/contact-section/contact-section.component';
import { ContactFormComponent } from './areas/contact/contact-form/contact-form.component'
import { FormsModule }   from '@angular/forms';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NavbarComponent,
    HeaderItemComponent,
    ResumeSectionComponent,
    ResumeItemComponent,
    AboutSectionComponent,
    TravelItemComponent,
    ContactSectionComponent,
    ContactFormComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule, 
    ProgressBarModule,
    FormsModule,
    NgCircleProgressModule.forRoot({
      radius: 70,
      outerStrokeWidth: 12,
      innerStrokeWidth: 5,
      outerStrokeColor: "#FBB829",
      innerStrokeColor: "#f2f2f2",
      animationDuration: 300,
      animation: true, 
      lazy:false,
      subtitle:'Proficient'
    })
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
