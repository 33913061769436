import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contact-section',
  templateUrl: './contact-section.component.html',
  styleUrls: ['./contact-section.component.css']
})
export class ContactSectionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  contactInfo: any = [
    {
    'awesomeIcon': 'fa fa-home', 
    'name': 'Address :',
    'data': '8300 Commerce Way #124 Miami Lakes, FL 33016'
    }, 
    {
      'awesomeIcon': 'fa fa-clock-o', 
      'name': 'Time Zone :',
      'data': 'EDT/EST (UTC-4)'
    }, 
    {
      'awesomeIcon': 'fa fa-phone', 
      'name': 'Phone :',
      'data': '(786) 538-8939'
    },
    {
      'awesomeIcon': 'fa fa-envelope-o', 
      'name': 'Email :',
      'data': 'mrsleanna.galarza@gmail.com'
    } ];
}
