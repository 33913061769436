import { Component, OnInit } from '@angular/core';
import { ContactForm } from '../../../contact-form';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.css']
})
export class ContactFormComponent implements OnInit {

  constructor() { }

  submitted: boolean = false;

  ngOnInit() {
  }

  model = new ContactForm('','','','');

  onSubmit(){
    document.getElementById('hiddenMailTo').click()
    this.submitted = true;
  }

  mailToLink(){
    var mailto = "mailto:mrsleanna.galarza@gmail.com?";
    var subject = "Subject=Hello!&";
    var body =  "Body=" + 
                "Name: " + this.model.name + "%0D%0A" + 
                "Email: " + this.model.email + "%0D%0A" + 
                "Phone: " + this.model.phone + "%0D%0A" +
                "Message: " + this.model.message;
    
    return mailto + subject + body;
  }
}
