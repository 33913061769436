import { Component, OnInit, Output } from '@angular/core';
import { HostListener, Inject } from "@angular/core";
import { EventEmitter } from 'events';
import { HomeComponent } from '../home/home.component';

@Component({
  providers: [HomeComponent],
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  constructor(private comp: HomeComponent) { }

  ngOnInit() {}

  public navigate(element: string) : void{
    this.comp.scroll(element);
  }

  @HostListener("window:scroll", [])
  onWindowScroll() {

    const number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (number > 300) {
      var navbar = document.getElementById('navContainer');
      if(!navbar.className.includes('scrolled'))
        navbar.className += ' scrolled';
    } 
    else{
      var navbar = document.getElementById('navContainer');
      if(navbar.className.includes('scrolled'))
        navbar.className = navbar.className.replace(' scrolled', '');
    }
  }

}
