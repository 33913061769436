import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-travel-item',
  templateUrl: './travel-item.component.html',
  styleUrls: ['./travel-item.component.css']
})
export class TravelItemComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  @Input() Info: any;
}
